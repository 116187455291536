.productRecommendation-container {
	color: var(--color-gray-dark);

	display: flex;
	flex-direction: column;
	gap: 15px;
}

.productRecommendation-container-circle {
	height: 16px;
	width: 16px;
	border-radius: 50%;
}

.productRecommendation-container>div {
	display: flex;

	align-items: center;
	justify-content: space-between;

	margin-right: 15px;

	cursor: pointer;
}

.productRecommendation-container>div>div {
	display: flex;
	gap: 13px;
}

.productRecommendation-container div:last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.productRecommendation-container div:last-child>div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.productRecommendation-container * {
	font-size: 12px;
}

.productRecommendation-container>div span {
	font-size: 14px;
	font-weight: 400;
	color: var(--color-gray);
}

.productRecommendation-container h4 {
	margin: 0;
	margin-top: 5px;
	text-transform: capitalize;
}

.productRecommendation-container h5 {
	font-weight: 400;
	margin: 0;
}

.productRecommendation-container h6 {
	font-weight: 500;
	margin: 0;
	margin-bottom: 5px;
}

.productRecommendation-container img {
	height: 10px;
	rotate: 90deg;
}

.productRecommendation-container span {
	color: var(--color-gray);
}