a:has(.cardMarketPlayer-container) {
	text-decoration: none;
}

.cardMarketPlayer-container,
.cardMarketPlayer-container-disabled {
	background-color: white;
	border: 1px solid var(--color-gray-ligth) !important;
	border-radius: 10px;
	width: 100%;
	padding: 10px;
	cursor: pointer;
	height: 108px;
}

.cardMarketPlayer-container:hover {
	opacity: 0.75;
}


.cardMarketPlayer-container-disabled {
	background-color: var(--color-gray-ligth);
}

.cardMarketPlayer-container h3 {
	font-size: 16px;
	font-weight: 300;
	margin-top: 5px;
	color: var(--color-gray-dark);
	text-transform: capitalize;
}

.cardMarketPlayer-container h4 {
	font-size: 14px;
	font-weight: 300;
	color: var(--color-gray-dark);
}

.cardMarketPlayer-container h6 {
	font-size: 12px;
	font-weight: 300;
	color: var(--color-gray);
	margin-bottom: 20px;
}

.cardMarketPlayer-container section {
	display: flex;
	gap: 10px;
	position: absolute;
	margin-left: 190px;
}

.cardMarketPlayer-container section img {
	height: 35px;
	border-radius: 6px;
	border: solid 1px var(--color-gray-blue);
	padding: 7px;
}

.marketPlayers-admin-button {
	display: none;

	width: 165px;
	height: 32px;
	border-radius: 7px;
	background-color: var(--color-gray-blue);
	color: white;
	border: none;
	font-size: 0.75rem;
	font-weight: 500;
	margin-bottom: 20px;
	align-items: center;
}

.marketPlayers-header {
	display: flex;
	margin-bottom: 10px;
	justify-content: space-between;
}

.marketPlayers-header img {
	height: 25px;
	width: 25px;
	left: auto;
}

.marketPlayers-cards-container {
	display: grid;
	position: relative;
	grid-template-columns: repeat(auto-fill, minmax(280px, 290px));
	grid-gap: 10px;
	justify-content: center;
	align-items: center;
}

.supplier-addNew-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	background-color: var(--color-gray-blue-dark);
	border: none;
}

@media (min-width: 744px) and (max-width: 1298px) {
	.marketPlayers-admin-button {
		margin-left: -25px;
	}
}

@media (min-width: 1298px) {
	.marketPlayers-header img {
		display: none;
	}

	.marketPlayers-admin-button {
		width: 220px;
		height: 47px;
		font-size: 0.7rem;

		display: flex;
		gap: 20px;
	}
}