.quotation-container {
	padding: 33px 15px 0 15px;
}

.quotation-container input {
	width: 50px;
	height: 20px;
	margin-bottom: 8px;
}

.quotation-item-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 320px;
	padding: 10px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
}

.quotation-item-container div {
	display: flex;
	justify-content: space-between;
}

.quotation-item-container div div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.quotation-item-container div div select {
	padding: 2px 7px;
}

.quotation-item-container h2 {
	font-size: 12px;
	font-weight: bold;
	margin: 5px 0;
}

.quotation-item-container a {
	text-decoration: none;
}

.quotation-item-container h3,
.quotation-item-container h4 {
	font-size: 12px;
	color: var(--color-gray);
	font-weight: 500;
	margin: 0;
	text-transform: capitalize;
}

.quotation-item-container img {
	width: 40px;
	height: 40px;
	padding: 7px;
}

.quotation-item-container h5,
.quotation-item-container h6 {
	font-size: 12px;
	font-weight: 300;
	margin-bottom: 4px;
}

.quotation-item-container h5 {
	color: var(--color-gray-blue);
	font-weight: 500;
}

.quotation-item-container input {
	width: 50px;
	height: 25px;
}

.quotation-item-container section {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 40px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.quotation-options-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 20px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	margin-bottom: 25px;
	height: fit-content;
}

.quotation-options-container button {
	border-radius: 8px;
	background-color: white;
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	padding: 7px;
}

.quotation-options-container section {
	width: 100%;
	padding: 15px;
}

.quotation-options-container section button {
	border: solid 1px var(--color-red);
	background-color: var(--color-red);
	color: white;
	height: 40px;
	width: 100%;
	margin-top: 10px;
}

.quotation-options-container button,
.quotation-options-container section button:nth-last-child(1) {
	border: solid 1px var(--color-gray-blue);
	background-color: var(--color-gray-blue);
	color: white;
	margin-top: 10px;
	margin-bottom: 0;
}

.quotation-options-container section div {
	display: flex;
	justify-content: space-between;
	padding: 1px 0;
}

.quotation-options-container section:nth-child(1) {
	padding-bottom: 0px;
}

.quotation-options-container h3,
.quotation-options-container h4 {
	font-size: 13px;
	font-weight: 300;
}

.quotation-options-container h4 {
	font-weight: 500;
}

.quotation-options-container h5 {
	font-size: 14px;
	margin: 0;
	color: var(--color-gray);
}

.quotation-options-container hr {
	margin-top: 3px;
	margin-bottom: 7px;
	height: 2px;
}

.quotation-options-container .mantine-InputWrapper-root {
	width: 90%;
	margin: 10px auto 0px auto;
}

.quotation-options-container .mantine-InputWrapper-root label {
	color: var(--color-gray-dark);
	font-size: 14px;
	margin-left: 4px;
}

.quotation-options-container .mantine-InputWrapper-root select {
	font-size: 13px;
}

.quotation-options-header {
	width: 94%;
	display: flex;
	align-items: flex-end;
	gap: 10px;
	margin-right: 10px;
}

.quotation-options-header>div:last-child {
	width: 30%;
}

.quotation-options-observations {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 12px;
}

.quotation-options-observations button {
	margin-top: 5px;
	width: 50%;
	margin-left: 50%;
}

.quotation-options-observations h4 {
	margin-left: 3px;
	font-size: 14px !important;
	color: var(--color-gray-dark);
}

.quotation-options-observations textarea {
	border: 1px solid var(--color-gray-ligth);
	border-radius: 5px;
	font-size: 12px;
	resize: none;
	height: 120px;
}

.quotation-options-last-section h5 {
	color: var(--color-gray-blue-dark);
}

.quotation-options-last-section hr {
	color: var(--color-gray-blue-dark);
	opacity: 0.75;
}

.quotation-options-state {
	margin-top: 10px;
	width: 100%;
	display: flex !important;
	justify-content: center !important;
}

.quotation-options-state * {
	margin: 0 !important;
	height: 40px;
	min-height: fit-content;
}

.quotation-options-state>div {
	width: 100% !important;
}

.quotation-options-state>div>div {
	width: 100%;
}

.quotation-item-salePrice {
	padding: 5px;
	font-size: 10px;
	margin-top: -18px;
	color: var(--color-gray-blue-dark);

	background-color: white;
	border-radius: 0 0 10px 10px;
	display: flex;
	justify-content: space-around;
	font-weight: 500;
	margin-bottom: 20px;
	border: 1px solid var(--color-gray-ligther);
	border-top: 1px solid var(--color-gray-blue-dark);
	width: 320px;
}

.quotation-item-salePrice h6 {
	font-size: 12px;
	font-weight: 400;
	margin: 0;
	color: var(--color-gray-blue-dark);
}

.quotation-item-salePrice span {
	font-weight: 600;
	color: var(--color-gray-blue-dark);
}

.quotation-item-salePrice div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 !important;
}

.quotation-edit-options {
	display: flex;
	flex-direction: row !important;
	gap: 5px;
	margin: 0 !important;
}

.quotation-edit-options img {
	height: unset;
}

.quotation-item-container>h4 {
	color: var(--color-red);
	font-weight: 400;
	margin-bottom: 0;
}

.quotation-item-container .mantine-NativeSelect-root {
	width: 200px;
}

.quotation-stock-quantity-container {
	justify-content: flex-start !important;
	gap: 40px;
}

.quotation-stock-quantity-container section {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	height: auto;
}

.quotation-stock-quantity-container input {
	width: 50px;
}

.quotation-stock-quantity-item {
	display: none !important;
}

.quotation-item-closed-container img {
	height: 30px;
}

.quotation-item-closed-container div:last-of-type {
	justify-content: flex-start;
	gap: 25px;
}

.quotation-item-closed-container section {
	flex-direction: row;
	gap: 5px;
	margin: 0;
	height: auto;
}

.quotation-container-grid div:has(span) {
	width: 100%;
	justify-content: center;

	color: var(--color-gray-dark);
}

@media (min-width: 744px) and (max-width: 1297px) {
	.quotation-container {
		padding: 33px 60px 0 60px;
	}

	.quotation-container>div:nth-child(3) {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 30px;
	}

	.quotation-container-grid {
		display: flex;
		margin: 0;
		justify-content: space-between;
	}

	.quotation-container-grid div:has(span) {
		width: 50%;
	}

	.quotation-options-container {
		margin: 0 !important;
		width: 300px;
	}

	.quotation-options-container h3,
	.quotation-options-container h4,
	.quotation-options-container h5 {
		font-size: 13px;
		margin-bottom: 6px;
	}

	.quotation-options-container .mantine-InputWrapper-root {
		width: auto;
		margin-left: 10px;
	}

	.quotation-options-state>div {
		width: 100% !important;
	}
}

@media (min-width: 1298px) {
	.quotation-container-grid {
		display: flex;
		margin: 0;
		justify-content: space-between;
	}

	.quotation-container-grid div:has(span) {
		width: 50%;
	}

	.quotation-options-container {
		margin: 0 !important;
		width: 320px;
	}

	.quotation-item-container h2 {
		font-size: 16px;
		margin: 5px 0;
	}

	.quotation-item-container h3,
	.quotation-item-container h4 {
		font-size: 14px;
		margin: 0;
	}

	.quotation-item-container h5,
	.quotation-item-container h6 {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 4px;
	}

	.quotation-options-container {
		right: unset;
		left: 45%;
	}

	.quotation-container {
		padding: 33px 105px 0 105px;
	}

	.quotation-container>div:nth-child(3) {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 30px;
	}

	.quotation-item-container {
		width: 780px;
	}

	.quotation-options-container {
		margin-top: 0;
		width: 346px;
	}

	.quotation-item-salePrice {
		margin-top: -18px;
		width: 780px;
	}

	.quotation-item-salePrice h6 {
		font-size: 14px;
		font-weight: 400;
		margin: 0;
	}

	.quotation-item-salePrice span {
		font-size: 14px;
	}

	.quotation-item-salePrice div {
		flex-direction: row;
		gap: 5px;
	}

	.quotation-options-container h3,
	.quotation-options-container h4,
	.quotation-options-container h5 {
		font-size: 14px;
		margin-bottom: 6px;
	}

	.quotation-options-state>div {
		width: 100% !important;
	}

	.quotation-stock-quantity-container {
		display: none !important;
	}

	.quotation-stock-quantity-item {
		display: block !important;
	}
}