.textSearcher-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: var(--color-gray);
    border: 1px solid var(--color-gray-ligth);
    background-color: white;
    border-radius: 6px;
    width: 240px;
}

.textSearcher-container input {
    border: none !important;
    width: 90%;
    background-color: transparent;
    font-weight: 300;
    font-size: 0.88rem;
    color: var(--color-gray-dark);
}


@media (max-width: 744px) {
    .textSearcher-container {
        width: 100%;
    }

    .textSearcher-container input {
        width: 100%
    }
}

@media (min-width: 744px) and (max-width: 1298px) {
    .textSearcher-container {
        width: 290px;
    }

    .textSearcher-container input {
        width: 260px
    }
}

@media (min-width: 1298px) {
    .textSearcher-container input {
        font-size: 0.8rem;
        width: 290px
    }

    .textSearcher-container {
        width: 277px;
        height: 48px;
    }
}