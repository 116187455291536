.notFound-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	color: var(--color-gray-dark);
	align-items: center;
	margin-top: 10vh;
	margin-bottom: 50px;
}

.notFound-container img {
	padding-top: 30px;
	width: 360px;
}

.notFound-container h2 {
	margin-top: 60px;
	font-size: 1.25rem;
}

.notFound-container h3 {
	margin-top: 4px;
	font-size: 1rem;
	font-weight: 300;
}

.notFound-container button {
	margin-top: 24px;
	width: 146px;
	padding: 11px;
	border: none;
	border-radius: 5px;
	background-color: var(--color-gray-blue-dark);

	font-size: 0.67rem;
	font-weight: bold;
	color: white;
}

@media (min-width: 744px) and (max-width: 1298px) {
	.notFound-container img {
		padding-top: 30px;
		width: 493px;
	}

	.notFound-container h2 {
		margin-top: 67px;
		font-size: 2.1875rem;
	}

	.notFound-container h3 {
		margin-top: 4px;
		font-size: 1.2rem;
	}

	.notFound-container button {
		margin-top: 24px;
		width: 191px;
		padding: 11px;

		font-size: 1rem;
	}
}

@media (min-width: 1298px) {
	.notFound-container img {
		padding-top: 30px;
		width: 493px;
	}

	.notFound-container h2 {
		margin-top: 67px;
		font-size: 2rem;
	}

	.notFound-container h3 {
		margin-top: 4px;
		font-size: 1rem;
	}

	.notFound-container button {
		margin-top: 24px;
		width: 191px;
		padding: 11px;

		font-size: 0.8rem;
	}
}