.cardDashboard-options {
	position: absolute;
	z-index: 2;
	width: 212px !important;
	height: auto !important;
	margin-left: 26%;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	background: white;
	border-radius: 10px;
}

.cardDashboard-options-expand {
	width: 178px;
	height: 40px;
	margin: 5px 0;
	border-radius: 7px;
	background-color: transparent;
	color: var(--color-gray-blue);
	border: 1px solid var(--color-gray-blue);
	font-size: 0.75rem;
	font-weight: 500;
}

.home-container {
	margin: auto;
	margin-top: 80px;
	width: 360px;
}

.modales-cerrar_modal_home-img {
	position: absolute;
	right: 12px;
	top: 12px;
}

.home-add_card {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 200px;
	cursor: pointer;
}

.home-contenedor-card {
	background-color: white;
	border: 1px solid var(--color-gray-ligther);
	border-radius: 10px;
	width: 100%;
	padding: 20px;
}

.home-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: -7px;
}

.home-contenedor-card h3 {
	color: var(--color-gray-blue);
	font-size: 0.75rem;
	font-weight: 500;
	margin: 0;
}

.home-contenedor-card section {
	display: flex;
	gap: 5px;
}

.home-contenedor {
	display: grid;
	justify-items: center;
	grid-template-columns: repeat(1, 1fr);
	gap: 40px;
	margin-bottom: 50px;
	margin-top: -30px !important;
	padding: 45px 0 !important;
}

.home-contenedor hr {
	margin: 10px 0;
	width: 100%;
	height: 2px;
	background-color: var(--color-gray-ligth);
}

.modalEditGraphic-container {
	display: flex;
	flex-direction: column;
}

.modalEditGraphic-container button {
	align-self: center;
	margin: 0;
	width: 100%;
}

.modalEditGraphic-container input,
.modalEditGraphic-container select {
	margin-bottom: 15px;
	height: 35px;
	padding: 5px 10px;
	border-radius: 3px;
	border: solid 1px var(--color-gray-ligth);
	background-color: white;
}

.modalEditGraphic-container span {
	margin-bottom: 15px;
	width: 100%;
}

.modalEditGraphic-container .selectCategory-container {
	width: 260px;
	margin-top: 65px;
}

select[disabled] {
	background-color: var(--color-gray-ligth);
}

.home-welcome-container {
	margin: 20px 0;
}

.home-welcome-container div {
	display: flex;
	align-items: center;
	gap: 8px;
}

.home-welcome-container h1 {
	margin: 0;
	font-size: 45px;
	font-weight: bold;
	color: var(--color-gray-blue-dark)
}

.home-welcome-container h3 {
	margin: 0;
	font-size: 16px;
	color: var(--color-gray-dark)
}

.home-welcome-container span {
	margin: 0;
	background-color: var(--color-gray-blue-dark);
	border-radius: 6px;
	color: white;
	padding: 2px 5px;
	font-size: 14px;
	font-weight: 600;
}

@media (min-width: 744px) and (max-width: 1298px) {
	.home-container {
		width: 744px;
	}

	.home-contenedor-card {
		padding: 30px;
	}

	.modal-grafico-div {
		min-height: 250px;
	}
}

@media (min-width: 1298px) {
	.home-container {
		margin-top: 120px;
		width: 1298px;
	}

	.home-container .menuItems-container {
		display: none;
	}

	.home-contenedor-card {
		padding: 30px;
	}

	.home-contenedor {
		grid-template-columns: repeat(2, 0.5fr);
	}

	.modal-grafico-div {
		min-height: 500px;
	}

	.modalEditGraphic-container .selectCategory-container {
		margin-top: 70px;
	}

	.home-welcome-container div {
		gap: 12px;
	}

	.home-welcome-container h1 {
		font-size: 65px;
	}

	.home-welcome-container h3 {
		font-size: 20px;
	}

	.home-welcome-container span {
		border-radius: 6px;
		padding: 3px 7px;
		font-size: 16px;
	}
}

@media (min-width: 1600px) {
	.home-contenedor-card {
		padding: 30px;
	}

	.home-contenedor {
		width: 100% !important;
	}
}