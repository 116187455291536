.charts-div {
	height: 186px;
	margin: auto;
}

@media (min-width: 744px) {
	.charts-div {
		height: 263px;
	}
}

@media (min-width: 1600px) {
	.charts-div {
		height: 333px;
	}
}