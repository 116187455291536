.carga-planillas-contenedor button {
	width: 300px;
	height: 40px;
	border-radius: 7px;
	background-color: var(--color-gray-blue);
	color: white;
	border: none;
	font-size: 14px;
	font-weight: 500;
	margin: 10px 0;
}

.carga-planillas-contenedor label {
	font-size: 14px;
	color: var(--color-gray-dark);
}

.carga-planillas-contenedor section {
	display: flex;
	flex-direction: column;

	margin: 10px 0;
	width: 300px;
}

.carga-planillas-contenedor section select {
	color: var(--color-gray-dark);
	background-color: white;
	border: 1px solid var(--color-gray-ligth);
	font-weight: 300;
}

.carga-planillas-contenedor section div:nth-of-type(2) {
	margin-top: 10px;
}

.cargar-planillas-fileSelect input {
	display: none;
}

.cargar-planillas-fileSelect label {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-bottom: 10px;
}

.cargar-planillas-fileSelect span:nth-child(1) {
	padding: 7px 20px;
	border-radius: 9px;
	background-color: transparent;
	color: var(--color-gray-blue);
	border: 1px solid var(--color-gray-blue);
	background-color: white;
	font-size: 14px;
	font-weight: 500;
	width: 135px;
	height: 36px;
	text-align: center;
	cursor: pointer;
}

.cargar-planillas-fileSelect span:nth-child(1):hover {
	opacity: 0.75;
}

.cargar-planillas-fileSelect span:nth-child(2) {
	font-size: 14px;
	color: var(--color-gray);
	width: 130px;
	overflow: overlay;
}

.carga-planillas-contenedor div>span {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	width: 300px;
	height: 25px;
	padding: 15px 10px;
	border-radius: 5px;
	border: solid 1px var(--color-gray-ligth);
	background-color: white;
	text-transform: capitalize;
	color: var(--color-gray-dark)
}

.button[disabled="disabled"],
button:disabled {
	background-color: var(--color-gray-blue-ligth);
}

.spreadSheetHistory-container {
	margin-top: 0;
	padding-top: 0;
}

.spreadSheetHistory-container section {
	width: 100%;
	background-color: white;
	border-radius: 6px;
	border: solid 1px #bfbdbf;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	margin-bottom: 10px;
}

.spreadSheetHistory-container h5,
.spreadSheetHistory-container h4 {
	font-size: 16px;
	color: var(--color-gray-blue);
	font-weight: normal;
	margin: 0;
}

.spreadSheetHistory-container h5 {
	color: var(--color-gray-dark);
	font-weight: 300;
	font-size: 0.75rem;
	width: 10%;
}

.spreadSheetHistory-container section img {
	height: 35px;
	border-radius: 6px;
	border: solid 1px var(--color-gray-blue);
	padding: 7px;
}

.spreadSheetHistory-filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	color: var(--color-gray);
	border: 1px solid var(--color-gray-ligth);
	background-color: white;
	border-radius: 6px;
	width: 240px;

	margin-top: 15px;
	font-size: 14px;
	font-weight: 300;
	text-transform: capitalize;
	cursor: pointer;
}

.spreadSheetHistory-history {
	margin-top: 20px;
}

.mantine-Input-input {
	background-color: white !important;
}

@media (max-width: 744px) {
	.spreadSheetHistory-filter {
		width: 100%;
	}
}

@media (min-width: 744px) {

	.carga-planillas-contenedor button {
		width: 283px;
		margin-left: 165px;
	}

	.carga-planillas-contenedor div>span {
		width: 210px;
	}

	.carga-planillas-contenedor label {
		width: 150px;
	}

	.carga-planillas-contenedor select {
		width: 250px;
	}

	.carga-planillas-contenedor section {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;

		margin: 10px 0;
		min-width: 712px;
	}

	.carga-planillas-contenedor section div:nth-of-type(2) {
		margin-top: 0px;
	}

	.cargar-planillas-fileSelect label {
		width: 500px;
		justify-content: unset;
	}


	.cargar-planillas-fileSelect span:nth-child(2) {
		width: 350px;
	}

	.spreadSheetHistory-container section {
		max-width: 910px;
	}

	.spreadSheetHistory-container section img {
		height: 35px;
		width: 35px;
	}

	.spreadSheetHistory-filter {
		margin: 0;
	}

	.spreadSheetHistory-filters {
		display: flex;
		flex-direction: row;
		gap: 25px;
		margin-bottom: 15px;
	}

	.spreadSheetHistory-history {
		display: grid;
		grid-template-columns: repeat(2, 300px);
		column-gap: 25px;
		margin: 0;
	}

	.carga-planillas-contenedor section select {
		width: 283px;
	}
}

@media (min-width: 1298px) {

	.carga-planillas-contenedor label,
	.carga-planillas-contenedor select,
	.carga-planillas-contenedor input,
	.carga-planillas-contenedor div>span {
		font-size: 0.75rem;
	}

	.carga-planillas-contenedor section {
		margin: 15px 0;
	}

	.cargar-planillas-fileSelect span:nth-child(2) {
		width: 350px;
	}

	.spreadSheetHistory-container h5 {
		font-size: 0.6rem;
	}

	.spreadSheetHistory-container h4 {
		font-size: 0.8rem;
	}

	.spreadSheetHistory-container section img {
		height: 35px;
		width: 35px;
	}

	.spreadSheetHistory-history {
		display: grid;
		grid-template-columns: repeat(3, 394px);
		column-gap: 25px
	}
}