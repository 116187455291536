.mantine-Paper-root {
    padding: 15px;
    border: 1px solid var(--color-gray-ligth) !important;
    width: 100% !important;
}

.mantine-Paper-root>div {
    margin-top: 5px !important;
}

.statsSegment-title {
    font-weight: 700 !important;
    color: var(--color-gray-dark) !important;
}