.modales-cerrar_modal-img {
	position: absolute;
	right: 12px;
	top: 12px;
	cursor: pointer;
}

.login-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0px !important;
	padding: 0 !important;
	height: 100vh;
}

.login-container p {
	color: var(--color-red);
	text-align: center;
}

.login-titulo {
	font-size: 1.25rem;
	font-weight: 300;
	color: var(--color-gray-dark);
	text-align: center;
}

.login-contenedor_imagen {
	width: 100%;
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-imagen {
	width: 250px;
	height: 250px;
}

.login-logos_menores {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 42px;
	width: 250px;
}

.login-logos_menores img {
	width: 80px;
	height: 80px;
}

.login-logos_menores .linea-separadora-signIn {
	display: block;
	height: 30px;
	margin: 0;
}

.linea-separadora-signIn {
	background-color: var(--color-gray-blue);
	width: 3px;
	height: 80vh;
	display: none;
}

.login-formulario-contenedor .login-titulo {
	display: none;
}

.login-formulario-contenedor h5 {
	margin: 0 auto;
	font-size: 16px;
	font-weight: 400 !important;
	color: var(--color-gray-dark)
}

.login-container form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-container input {
	width: 290px;
	border-radius: 5px;
	border: solid 0.5px var(--color-gray);
	padding: 10px;
	margin-top: 20px;
	font-size: 0.88rem;
	font-weight: 300;
	color: var(--color-gray);
}

.login-lable_contenedor {
	display: flex;
	width: 290px;
	justify-content: flex-end;
}

.login-container h4 {
	font-size: 0.88rem;
	font-weight: 300;
	color: var(--color-gray-dark);
	margin-top: 8px;
}

.login-container h4:hover {
	text-decoration: underline;
	text-decoration-color: var(--color-gray-blue);
	text-decoration-thickness: 2px;
}

.login-container button {
	width: 290px;
	margin-top: 20px;
	border-radius: 5px;
	background-color: var(--color-gray-blue);
	border: none;

	font-size: 0.88rem;
	font-weight: bold;
	padding: 10px;
	color: white;

	background: linear-gradient(90deg, var(--color-gray-blue-dark) 50%, var(--color-gray-blue) 50%);
	background-size: 200% 100%;
	transition: background-position 0.4s ease;
	background-position: 100% 0;
}

.login-container button:hover {
	background-position: 0 0;
}

.login-container button:focus {
	background: var(--color-gray-blue-ligther);
}

.login-container button:disabled {
	background: var(--color-gray-blue-ligther);
}

.login-modal-container {
	padding: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-modal-container img:nth-of-type(2) {
	width: 150px;
}

.login-modal-container input {
	width: 250px;
	border-radius: 5px;
	border: solid 0.5px var(--color-gray);
	padding: 10px;
	font-size: 0.88rem;
	font-weight: 300;
	color: var(--color-gray-dark);
	margin-top: 15px;
}

.login-modal-container button {
	width: 250px;
	margin-top: 13.8px;
	border-radius: 5px;
	background-color: var(--color-gray-blue);
	border: none;

	font-size: 0.88rem;
	font-weight: bold;
	padding: 10px;
	color: white;
}

.login-modal-container div {
	margin: 0 10px;
}

.login-modal-container h4,
.login-modal-container p {
	font-size: 1rem;
	font-weight: 300;
}

.login-recoverPassword-h4 {
	color: var(--color-red);
	font-weight: 400 !important;
	margin: 0;
}

@media (min-width: 744px) and (max-width: 1298px) {
	.login-titulo {
		font-size: 1.5rem;
	}

	.login-lable_contenedor,
	.login-container button,
	.login-container input {
		width: 302px;
		font-size: 1rem;
	}
}

@media (min-width: 1298px) {
	.login-contenedor-inputs {
		display: flex;
		align-items: center;
		padding: 30px;
	}

	.login-contenedor_imagen {
		width: 50%;
	}

	.login-titulo {
		display: none;
	}

	.login-formulario-contenedor {
		min-height: 80vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.login-formulario-contenedor .login-titulo {
		font-size: 2rem;
		display: block;
		margin: 0 0 50px 0;
	}

	.login-imagen {
		width: 400px;
		height: 400px;
	}

	.login-logos_menores {
		width: 350px;
	}

	.login-logos_menores img {
		width: 100px;
		height: 100px;
	}

	.login-lable_contenedor,
	.login-container button,
	.login-container input {
		width: 401px;
		font-size: 1rem;
	}

	.linea-separadora-signIn {
		display: block;
		margin: 0 100px 0 50px;
	}

	.login-modal-container h4,
	.login-modal-container p {
		font-size: 0.8rem;
	}
}