.addMarketPlayerModal-container input {
	width: 100%;
	border: none;
	border-bottom: 1px solid var(--color-gray-blue);
}

.addMarketPlayerModal-container section {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-bottom: 15px;
}

.modales-modal_editar_proveedor-ocultar {
	display: none;
}

.modales-proveedores_cont_datos {
	margin: 20px 0;
}

.modales-proveedores_campo_usuario {
	width: 50px;
}

.modales-texto_modal-campo_pass,
.modales-proveedores_campo_usuario {
	font-size: 0.88rem;
	width: auto;
	font-weight: 300;
	color: var(--color-gray-dark);
	line-height: 1.64;
	margin: 0;
}

.modales-modal_editar_proveedores-input_password {
	width: 55%;
	text-align: flex-end;
	border: none;
	border-bottom: 2px solid var(--color-gray-blue);
	color: black;
	font-size: 0.88rem;
	font-weight: 500;
	font-family: "password";
	margin-bottom: 10px;
}

.modales-proveedores_editar-input {
	width: 77%;
	text-align: flex-end;
	border: none;
	border-bottom: 2px solid var(--color-gray-blue);
	color: black;
	font-size: 0.88rem;
	font-weight: 500;
	margin-bottom: 10px;
}

.modales-texto_modal-campo_pass {
	width: 150px;
}

.modal-marketPlayer-edit-isEnabled {
	width: 100%;
	height: 49px;
	margin: 5px 0;
	border-radius: 7px;
	background-color: transparent;
	color: var(--color-gray-blue);
	border: 1px solid var(--color-gray-blue);
	font-size: 0.88rem;
	font-weight: 500;
}