.loadingProductCard-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 332px;
	margin-top: 30px;
}

.loadingProductCard-info {
	color: inherit;
	flex-direction: column;
	background-color: white;
	border-radius: 10px;
	width: 100%;
	min-height: 475px;
	border: 1px solid var(--color-gray-ligth);

    display: flex;
    justify-content: center;
}
