.filtros-contenedor_filtros_cards {
	margin-top: 25px;
	display: grid;
	justify-items: center;
	position: relative;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 10px;
}

.filtros-tarjeta_filtros-titulo_input {
	border: none;
	width: 100%;
	font-size: 1rem;
	color: var(--color-gray-dark);
}

.filtros_tarjeta-container hr {
	width: 100%;
	margin: 0;
	margin-bottom: 12px;
	border: 1px solid #0054ea;
	opacity: 0.5;
	color: var(--color-gray);
}

.filtros_tarjeta-container {
	width: 290px;
	height: 292px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligther);
	padding: 0px 19px;
	margin-bottom: 25px;
}

.filtros_tarjeta-container div:nth-child(1) {
	display: flex;
	justify-content: space-between;
}

.filtros_tarjeta-container div:nth-child(1) div:nth-child(1) {
	display: flex;
	flex-direction: column;
	margin-top: 17px;
	width: 80%;
}

.filtros-tarjeta_filtro-icono_borrar {
	width: 35px;
	height: 35px;
	margin-top: 17px;

	border-radius: 6px;
	border: solid 1px var(--color-gray-blue);
	padding: 7px;
}

.filtros-tarjeta_filtros-checks_div {
	height: 155px;
	overflow: scroll;
}

.filtros-tarjeta_filtros-checks_div::-webkit-scrollbar {
	width: 0.4em;
	height: 50%;
}

.filtros-tarjeta_filtros-checks_div::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.filtros-tarjeta_filtros-checks_div::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.filtros-check_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.filtros-tarjeta_filtros-icono_borrar {
	width: 10px;
	height: 10px;
}

.filtros-tarjeta_filtros-icono_nuevo_filtro {
	margin-top: 10px;
}

.filtros-checkbox,
.filtros-tarjeta_filtros-icono_nuevo_filtro {
	width: 24px;
	height: 24px;
}

.filtros-tarjeta_filtros-inputs {
	font-size: 0.88rem;
	font-weight: 300;
	border: 0px;
	margin-top: 5px;
}

.modales-modal_guardar-boton {
	width: 252px;
	height: 39px;
	margin-top: 20px;
	border-radius: 7px;
	font-size: 0.88rem;
}

.filtros-tarjeta_filtros-tarjeta_nuevo {
	position: relative;
	height: 292px;
	width: 290px;
	border-radius: 9px;
	border: 1px solid var(--color-gray-ligther);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;
	background-color: white;
}

.filtros-tarjeta_filtros-tarjeta_nuevo img {
	height: 80px;
	width: 80px;
}

.filtros-tarjeta_filtros-tarjeta_nuevo img:hover {
	opacity: 0.75;
}

.filtros-modal_error_row img:nth-child(2) {
	width: 90px;
	margin: 10px 0 10px 89px;
}

.filtros-modal_error_row h3 {
	font-size: 1rem;
	color: var(--color-red);
	text-align: center;
}

.filtros-modal_error_row p {
	font-size: 1rem;
	text-align: center;
	line-height: 1.64;
	font-weight: 300;
	margin-bottom: 0;
}

.filtros-modal_error_row {
	align-items: center;
	gap: 10px;
	overflow: hidden;
}

.filtros-select_category {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	width: 250px;
	height: 25px;
	padding: 15px 10px;
	border-radius: 3px;
	border: solid 1px var(--color-gray-ligth);
	background-color: white;
	text-transform: uppercase;

	font-size: 16px;
	font-weight: 500;
	color: var(--color-gray-dark);
}

@media (min-width: 1298px) {
	.filtros-tarjeta_filtros-titulo_input {
		font-size: 0.88rem;
	}

	.filtros-modal_error_row p,
	.filtros-modal_error_row h3 {
		font-size: 0.8rem;
	}

	.filtros-tarjeta_filtros-inputs,
	.modales-modal_guardar-boton {
		font-size: 0.7rem;
	}
}