.commercialization-container {
    margin: 80px auto 20px auto;
    width: 360px;
}

.commercialization-add-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 174px;
    margin-bottom: 25px;
    border-radius: 9px;
    border: none;
    background-color: var(--color-gray-blue-dark);
    padding: 10px 0;
}

.commercialization-add-button:hover {
    background-color: var(--color-gray-blue);
}

.commercialization-add-button:focus {
    background-color: var(--color-gray-blue-ligther);
}

.commercialization-add-button img {
    height: 25px;
}

.commercialization-section-container>div:nth-child(3)>section {
    margin-bottom: 20px;
}

.commercialization-section-container>section {
    margin-bottom: 30px;
}

.commercialization-section-container section {
    display: flex;
    gap: 7.6px;
}

.commercialization-section-container section:last-child {
    justify-content: flex-end;
}

.commercialization-section-container section img {
    width: 16.4px;
}

.commercialization-section-container section p {
    color: var(--color-gray-dark);
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0;
}

.priceList-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.priceList-list-container>span {
    width: 100px;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: right;
    color: #3c4d66;
}

.priceList-list-container div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: white;
    border: 1px solid var(--color-gray-ligth);
    border-radius: 6px;

    color: var(--color-gray-dark);
    font-size: 0.8rem;
    font-weight: 300;

    height: 45px;
    margin: 5px 0 10px 0;
    padding: 10px;
    width: 100%;
}

.priceList-list-container h5 {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

.priceList-list-container input {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 300;
    width: 130px;

    border: none;
    border-bottom: 1px solid #0054ea;
    color: var(--color-gray-dark);
}

.priceList-list-container section input:nth-child(2) {
    width: 35px;
    text-align: flex-end;
}

.priceList-list-container section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.priceList-list-container img {
    width: 19px;
}

.priceList-list-options_container {
    position: absolute;
    z-index: 2;
    width: 212px !important;
    height: auto !important;
    right: 25px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
}

@media (min-width: 744px) and (max-width: 1298px) {
    .commercialization-container {
        margin: 100px auto 20px auto;
        width: 744px;
    }

    .priceList-list-container {
        width: 544px;
    }

    .priceList-list-options_container {
        right: 20%;
    }

    .priceList-list-container div {
        padding: 10px 4px 10px 10px;
    }
}

@media (min-width: 1298px) {
    .commercialization-container {
        margin: 120px auto 20px auto;
        width: 1298px;
    }

    .priceList-list-container {
        width: 544px;
    }

    .priceList-list-options_container {
        left: 40%;
    }

    .priceList-list-container div {
        padding: 10px 4px 10px 10px;
    }

    .priceList-list-container input {
        width: 170px;
    }

    .priceList-list-container section input:nth-child(2) {
        width: 50px;
    }

}