hr {
    height: 2px !important;
    border: 1px solid var(--color-gray-dark);
    opacity: 0.75;
}

h2 {
    font-weight: 400 !important;
}

.quotationRecord-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: white;
    border: 1px solid var(--color-gray-ligth);
    border-radius: 10px;
}

.quotationRecord-card img {
    height: 30px;
    border: 1px solid var(--color-gray-blue);
    border-radius: 7px;
}

.quotationRecord-card h4 {
    color: var(--color-gray-blue);
    margin: 0;
    font-size: 16px;
}

.quotationRecord-card h5 {
    color: var(--color-gray);
    margin: 0;
    font-size: 12px;
}

.quotationRecord-card span {
    color: transparent;
    width: 15px;
    height: 49px;
    border-radius: 0 10px 10px 0;
}

.quotationRecord-card-rigth {
    display: flex;
    gap: 10px;
    align-items: center;
}

.quotationRecord-cards-container {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(1, auto);
    margin-top: 15px;
}

.quotationRecord-filters-container {
    display: flex;
    flex-direction: column;
}

.quotationRecord-filters-container>div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
    align-items: flex-end;
}

.quotationRecord-filters-container section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.quotationRecord-filters-container section div {
    display: flex;
    gap: 20px;
    align-items: center;
}

.quotationRecord-filters-container section div div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.quotationRecord-filters-container section div h6 {
    color: var(--color-gray-dark);
    margin: 0;
    font-size: 16px;
}

.quotationRecord-filters-container section div span {
    width: 20px;
    height: 20px;
    color: transparent;
    border: 1px solid var(--color-gray-dark);
}

.quotationRecord-cards-pagination {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-gray-dark);
    margin: 15px auto;
    display: flex;
    justify-content: flex-end;
    width: 97%;
    cursor: pointer;
}

.quotationRecord-cards-pagination img {
    width: 15px;
}

.quotationRecord-graphs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 25px;
}

@media (min-width: 744px) and (max-width: 1297px) {
    .quotationRecord-cards-container {
        grid-template-columns: repeat(2, 49%);
    }

    .quotationRecord-filters-container>div {
        display: flex;
        flex-direction: row;
    }

    .quotationRecord-filters-container>div>div {
        width: 30%;
    }

    .quotationRecord-filters-container section {
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;
    }
}

@media (min-width: 1297px) {
    .quotationRecord-cards-container {
        grid-template-columns: repeat(4, 24%);
    }

    .quotationRecord-filters-container>div {
        display: flex;
        flex-direction: row;
    }

    .quotationRecord-filters-container>div>div {
        width: 30%;
    }

    .quotationRecord-filters-container section {
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;
    }

    .quotationRecord-filters-dates {
        width: 49%;
    }
}