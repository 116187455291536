.categories-container>div:first-of-type {
	display: flex;
	gap: 25px;
	align-items: center;
	margin-bottom: 15px;
}

.categories-container>div:first-of-type button {
	margin: 0;
	border: 1px solid var(--color-gray-blue-dark);
	border-radius: 7px;
	padding: 5px 30px;
	font-weight: 700;
	font-size: 20px;
	color: var(--color-gray-blue-dark);
	background-color: white;
}

.categories-container>div:last-of-type {
	display: grid;
	grid-template-columns: repeat(1, 316px);
	grid-gap: 15px;
	padding-bottom: 75px;
}

.categories-container>div:last-of-type>div {
	margin: 0;
}

.categories-item {
	display: flex;
	align-items: center;
	border-radius: 7px;
	border: 1px solid var(--color-gray-ligth);
	padding: 15px;
	background-color: white;
	color: var(--color-gray-blue-dark);
	text-transform: uppercase;
}

.categories-item-dragging {
	box-shadow: var(--mantine-shadow-sm);
}

.categories-item-dragHandle {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
	padding-left: 10px;
	padding-right: 25px;
}

.categories-item-image {
	border: 1px solid var(--color-gray-blue-dark);
	background-color: var(--color-gray-blue-dark);
}

.categories-item section div {
	background-color: var(--color-gray-blue-dark);
	width: 50px;
	height: 40px;
	border-radius: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.categories-item section {
	display: flex;
	align-items: center;
	gap: 15px;
}

.categories-item p {
	font-size: 16px;
	font-weight: 800;
}

/* .categories-container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	justify-items: center;
	gap: 10px;
	margin-bottom: 25px;
}

.categories-container div {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	gap: 15px;

	position: relative;
	height: 80px;
	width: 290px;

	border-radius: 9px;
	border: 1px solid var(--color-gray-ligth);
	background-color: white;
	text-transform: uppercase;
}

.categories-container div:hover {
	opacity: 0.75;
}

.categories-container h4 {
	margin: 0;

	color: var(--color-gray-blue);
	font-size: 1rem;
	font-weight: normal;
}

.categories-container img {
	height: 40px;
	width: 40px;
	border: 1px solid var(--color-gray-blue-dark);
	background-color: var(--color-gray-blue-dark);
	padding: 5px;
	border-radius: 25%;
}*/

.categories-item-add {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;

	position: relative;
	height: 60px;
	width: 50%;

	border-radius: 9px;
	text-transform: uppercase;

	border: none;
	background-color: var(--color-gray-blue-dark);
}

.categories-item-add img {
	border: none;
	height: 40px;
}

.categoryModal-container {
	height: 430px;
}

.categoryModal-container button {
	width: 90%;
	height: 49px;
	margin: 5px 15px;
	border-radius: 7px;
	font-size: 0.88rem;

	color: white;
	background-color: var(--color-gray-blue);
	border: none;
	margin-bottom: 0px;

	position: absolute;
	bottom: 20px;
}

.categoryModal-container button[disabled] {
	background-color: var(--color-gray-blue-ligth);
}

.categoryModal-container>div {
	width: 312px;
	margin-left: -15px;

	height: 255px;
	overflow-x: hidden;
	scrollbar-width: thin;
	cursor: pointer;
}

.categoryModal-container div div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 34px;
	padding: 15px;
}

.categoryModal-container h3 {
	font-size: 0.75rem;
	font-weight: 300;
	color: var(--color-gray-blue);
	text-transform: uppercase;
	margin: 0;
}

.categoryModal-container h5 {
	color: var(--color-gray-blue);
	font-weight: normal;
	font-size: 0.88rem;
	margin: 0;

	text-transform: capitalize;
	width: 180px;
}

.categoryModal-container hr {
	margin: 10px 0;
	width: 312px;
}

.categoryModal-container section hr {
	margin-left: -15px;
}

.categoryModal-container div input {
	width: 200px;
	border: 0.5px var(--color-gray-ligth);
}

.categoryModal-icons {
	display: grid;
	grid-template-columns: repeat(5, auto);
	justify-content: center;
	align-items: center;
	column-gap: 20px;
	row-gap: 12px;
	height: 125px;
	overflow: scroll;
	overflow-x: hidden;
	margin-top: 10px;
}

.categoryModal-icons img {
	height: 40px;
	width: 40px;
	padding: 5px;
}

.categoryModal-selected-icon {
	height: 35px;
	width: 35px;
	border: 1px solid var(--color-gray-ligth);
	border-radius: 5px;
	padding: 5px;
}

.addCategoryModal-container section {
	display: flex;
	width: inherit;
	gap: 5px;
	justify-content: space-between;
}

.addCategoryModal-container section h4,
.addCategoryModal-container section input {
	width: 150px;
	border: none;
	border-bottom: 2px solid var(--color-gray-blue);
	height: 30px;
	font-weight: normal;
	font-size: 0.88rem;
	margin-bottom: 5px;
	margin-right: 55px;
	line-height: 32px;
	text-transform: uppercase;
	padding-left: 0;
	padding-bottom: 0;
	overflow: hidden;
}

.addCategoryModal-container section h4 {
	width: 150px;
	border-bottom: 2px solid var(--color-gray-dark);
	;
}

.addCategoryModal-container section>img {
	height: 35px;
	width: 35px;
	border-radius: 6px;
	border: solid 1px var(--color-gray-blue);
	padding: 7px;
}

.categoryModal-subcategory-container {
	height: 375px !important;
	overflow: scroll;
}

.categoryModal-subcategory-container>div:nth-child(1) {
	display: flex;
	justify-content: flex-start;
	gap: 5px;
}

.categoryModal-subcategory-container h5 {
	color: var(--color-gray-dark);
}

.categoryModal-subcategory-indices {
	display: flex;
	flex-direction: column;
}

.categoryModal-subcategory-indices div {
	display: flex;
	justify-content: space-between;
	cursor: auto;
}

.categoryModal-subcategory-indices div img,
.categoryModal-subcategory-indices div:last-of-type {
	cursor: pointer;
}

.categoryModal-subcategory-indices section {
	display: flex;
	gap: 10px;
}

.categoryModal-container input {
	border-bottom: 1px solid var(--color-gray-blue) !important;
}


.categoryModal-selected-gridIcon {
	background-color: #e8effc;
	border: 1px solid var(--color-gray-blue);
	border-radius: 5px;
}

.category-moveIndex-container {
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;
}

.category-moveIndex-container button {
	background-color: var(--color-gray-blue-dark);
	border: none;
	border-radius: 7px;
	color: white;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	padding: 5px 20px;
	width: 300px;
}

.category-moveIndex-container button[disabled] {
	background-color: var(--color-gray);
	border: none;
	border-radius: 7px;
	color: white;
	cursor: not-allowed;
	font-size: 16px;
	font-weight: 500;
	padding: 5px 20px;
}

.category-moveIndex-container button:hover {
	background-color: var(--color-gray-blue);
}

.category-moveIndex-container button[disabled]:hover {
	background-color: var(--color-gray);
}

.category-moveIndex-selectIndex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	width: 300px;
	height: 25px;
	padding: 15px 10px;
	border-radius: 5px;
	border: solid 1px var(--color-gray-ligth);
	background-color: white;
	text-transform: capitalize;
	color: var(--color-gray-dark);
	cursor: pointer;
}

@media (min-width: 744px) {
	.categories-container>div:first-of-type {
		gap: 15px;
	}

	.categories-container>div:last-of-type {
		grid-template-columns: repeat(2, 316px);
	}

	.categories-item-add {
		width: 316px;
	}

	.category-moveIndex-selectIndex {
		width: 210px;
	}

	.category-moveIndex-container {
		flex-direction: row;
	}

	.category-moveIndex-container button {
		width: 135px;
	}
}

@media (min-width: 1298px) {
	.categories-container>div:last-of-type {
		grid-template-columns: repeat(3, 385px);
	}

	.categories-item-add {
		width: 385px;
	}

	.categories-item-image {
		padding: 5px;
	}

	.categories-container h4,
	.categoryModal-container button,
	.categoryModal-container h3,
	.categoryModal-container h5,
	.addCategoryModal-container section h4,
	.addCategoryModal-container section input {
		font-size: 0.75rem;
	}

	.category-moveIndex-selectIndex {
		font-size: 0.75rem;
	}
}