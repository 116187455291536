.entity-marketPlayer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.entity-marketPlayer-container>img {
    width: 30px;
    height: 30px;
    padding: 5px;
    background-color: white;
    border: 1px solid var(--color-gray-blue);
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 15px;
}

.entity-marketPlayer-container>img:hover {
    background-color: transparent;
}

.indexSearch-container {
    padding: 33px 17px 0 17px;
    width: 360px;
    margin: auto;
    margin-top: 85px;
}

.indexSearch-container form {
    margin-bottom: 32px;
}

.indexSearch-form-confirm-button {
    background-color: white;
    border: 1px solid var(--color-gray-blue);
    border-radius: 5px;
    color: var(--color-gray-blue);
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;

    height: 32px;
    width: 142px;
}

.indexSearch-container form article {
    border: 2px dashed var(--color-gray-ligth);
    margin-top: 20px;
    padding: 10px;
    width: 325px;
}

.indexSearch-container form article img {
    position: absolute;
    margin-left: 285px;
    margin-top: -130px;
    cursor: pointer;
}

.indexSearch-container form div section img {
    width: 13px;
}

.indexSearch-container form div div hr {
    margin: 7px 0;
    height: 2px;
}

.indexSearch-container form>section {
    display: flex;

    margin-bottom: 15px;
}

.indexSearch-container>section {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    align-items: center;
}

.indexSearch-container h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.indexSearch-container h4 {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
}

.indexSearch-container h5 {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
    color: var(--color-gray-dark);
}

.indexSearch-container input,
.indexSearch-container span {
    width: 100%;
    border: 1px solid var(--color-gray);
    border-radius: 3px;

    color: var(--color-gray-dark);
    font-size: 14px;
}

.indexSearch-container span {
    text-transform: capitalize;
}

.indexSearch-container strong,
.indexSearch-container p,
.indexSearch-container label {
    font-size: 14px;
    color: var(--color-gray-dark);
    font-weight: 300;
    margin: 0;
}

.indexSearch-container form>section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 10px;
}

.indexSearch-container form>div {
    margin: 20px 0 5px 0;
}

.indexSearch-container form div section {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 5px;
}

.indexSearch-container form div section label {
    margin-top: 0;
    width: 60%;
}

.indexSearch-form-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.indexSearch-form-buttons button {
    background-color: white;
    border: 1px solid var(--color-gray-blue);
    border-radius: 5px;
    color: var(--color-gray-blue);
    font-size: 14px;
    font-weight: 500;

    height: 40px;
    width: 150px;
}

.indexSearch-form-buttons button:last-child {
    background-color: var(--color-gray-blue);
    color: white;
}

.indexSearch-form-buttons button:disabled {
    background-color: var(--color-gray-blue-ligth);
    border: none;
    cursor: not-allowed;
}

.indexSearch-form-buttons img {
    height: 40px;
    background-color: white;
    border-radius: 6px;
    border: solid 1px var(--color-gray-blue);
    padding: 7px;
}

.indexSearchIndices-category {
    display: grid !important;
    grid-template-columns: repeat(2, 170px);
}

.indexSearchIndices-container h2 {
    font-size: 14px;
    color: var(--color-green)
}

.indexSearchIndices-container h3 {
    color: var(--color-gray-blue);
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0;
    text-transform: capitalize;
}

.indexSearchIndices-container h4 {
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.indexSearchIndices-container section {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    color: var(--color-gray-dark);
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;

    height: 40px;
    margin-bottom: 12px;
    padding: 12px 0;
    width: 160px;
}

.indexSearchIndices-category-error {
    border: 3px solid var(--color-red) !important;
}

.indexSearchIndices-category-warning {
    border: 3px solid var(--color-gray-blue) !important;
}

.indexSearchIndices-container div,
.indexSearchIndices-container div div {
    display: none;
}

.indexSearchIndices-container div:has(section),
.indexSearchIndices-container div div:has(section) {
    display: block;
}

.indexSearchIndices-container div:nth-child(1) {
    display: flex;
}

.indexSearchIndices-container div:nth-of-type(1) {
    margin: 0;
}

.indexSearchIndices-container>div {
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.select-category-anotation-section {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    gap: 5px;
    align-items: center;
}

@media (min-width: 744px) and (max-width: 1298px) {
    .indexSearch-container {
        width: 744px;
    }

    .indexSearchIndices-category {
        display: grid !important;
        grid-template-columns: repeat(3, 170px);
    }
}

@media (min-width: 744px) {
    .entity-marketPlayer-container>img {
        width: 35px;
        height: 35px;
    }

    .indexSearchIndices-container>div {
        flex-direction: row;
    }

    .indexSearchIndices-container>div h2 {
        margin: 0;
    }

    .indexSearch-container form article {
        border: none;
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 0;
        padding-bottom: 5px;
        width: 744px;
        border-bottom: 1px solid var(--color-gray);
    }

    .indexSearch-container form article hr {
        border: none;
    }

    .indexSearch-container strong {
        display: none;
    }

    .indexSearch-container form article p {
        width: 200px;
    }

    .indexSearch-container form article img {
        position: unset;
        margin: 0;
        margin-left: 100px;
    }

    .indexSearch-container form>section {
        width: 310px;
    }

    .indexSearch-container form>div:nth-last-of-type(3) {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-end;
        width: 744px;
    }

    .indexSearch-container form div section label {
        width: 100%;
    }

    .indexSearch-container form div:nth-of-type(1) section {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .indexSearch-container input {
        height: 32px;
    }

    .indexSearch-specialWords {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        gap: 10px;
        margin-top: 10px;
    }

    .indexSearch-specialWords section * {
        width: 310px;
    }
}

@media (min-width: 1298px) {
    .indexSearch-container {
        width: 1298px;
    }

    .indexSearchIndices-category {
        display: grid !important;
        grid-template-columns: repeat(5, 170px);
    }

    .indexSearchIndices-container h2 {
        font-size: 16px;
    }

    .indexSearchIndices-container h2 img {
        height: 18px;
        padding-bottom: 3px;
    }
}