.productCard-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 332px;
	margin: 10px 0;
	height: 100%;
}

.productCard-info {
	text-decoration: none;
	color: inherit;
	display: flex;
	justify-content: center;

	flex-direction: column;
	background-color: white;
	border-radius: 10px;
	width: 100%;
	min-height: 222px;
	margin-top: -40px;
	height: 100%;
	border: 1px solid var(--color-gray-ligth);
}

.productCard-info>div:nth-child(1) {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	padding: 0 15px;
}

.productCard-info>div:nth-child(1) h6 {
	font-size: 13px;
	font-weight: 400;
	color: var(--color-gray-blue)
}

.productCard-info>div:nth-child(1) span {
	font-size: 13px;
	font-weight: 600;
	color: var(--color-gray)
}

.productCard-info>div>div {
	overflow: hidden;
}

.productCard-options img {
	width: 30px;
	height: 30px;
	padding: 5px 0;
	border-radius: 5px;
	border: 1px solid var(--color-gray-blue);
	cursor: pointer;
}

.productCard-options {
	display: flex;
	position: relative;
	top: 10px;
	gap: 15px;
	padding: 5px 15px;
	align-items: center;
}

.productCard-options span {
	color: white;
	background-color: var(--color-gray-blue);
	border-radius: 7px;
	padding: 4px 8px;
	height: fit-content;
	font-size: 12px;
	font-weight: 600;
	line-height: normal;
}

.productCard-info h2 {
	font-size: 16px;
	color: var(--color-gray-dark);
	overflow: hidden;
	margin-bottom: 20px;
	margin-top: 10px
}

.productCard-info h4 {
	color: var(--color-gray-blue);
	font-size: 14px;
	font-weight: 400;
	margin: 0;
}

.productCard-info h6 {
	font-size: 14px;
	margin: 0;
}

.productCard-info section {
	border-top: 2px solid var(--color-gray-blue);
	padding: 5px;
	border-radius: 0 0 10px 10px;
	display: flex;
	justify-content: space-around;
	font-size: 16px;
	font-weight: 500;
	margin-top: -10px;
	color: var(--color-gray-blue);
}

.productCard-info section div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.productCard-info section span {
	font-weight: 600;
}

.productos-tarjeta_producto-stock-solapa-proveedor {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.productos-tarjeta_producto-stock-solapa {
	display: flex;
	margin-top: 3px;
}

.productos-tarjeta_producto-stock-solapa div {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--color-gray-blue);
	border-radius: 11px 11px 0 0;
	height: 20px;
	font-weight: 300;
	margin-left: 10px;
}

.productos-tarjeta_producto-stock-solapa-titulo {
	background-color: var(--color-gray-blue);
	width: 70px;
	z-index: 100;
}

.productos-tarjeta_producto-stock-solapa div:nth-child(2) {
	background-color: white;
	position: relative;
	left: -20px;
	padding-left: 30px;
	padding-right: 20px;
	font-size: 14px;
	margin: 0;
}

.productos-tarjeta_producto-sin_stock-solapa,
.productos-tarjeta_producto-stock-solapa-titulo>span {
	color: white;
	margin: 0;
	font-size: 12px;
	line-height: normal;
}

.productos-tarjeta_producto-stock-solapa-ubicaciones {
	display: none;
}

.productos-tarjeta_producto-stock-solapa-ubicaciones-btn {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 0;
	margin-bottom: -7px;
}

.productos-tarjeta_producto-stock-solapa-ubicaciones-btn ul {
	border-radius: 5px;
	border: 1px solid var(--color-gray-blue);
	box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.25);
	background-color: white;
	color: var(--color-gray-dark);
	font-size: 14px;

	padding-top: 5px;
	padding-right: 20px;
	left: -150px !important;
	width: fit-content;
	z-index: 10;
}

.productos-tarjeta_producto-stock-solapa-ubicaciones-btn-p {
	font-size: 12px;
	font-weight: 500;
	color: var(--color-gray-blue);
}

.productos-tarjeta_producto-sin_stock-solapa {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--color-gray-blue-ligther);
	background-color: var(--color-gray-blue-ligther);
	color: white;
	border-radius: 11px 11px 0 0;
	height: 21px;
	width: 80px;
	z-index: 30;
	margin-left: 10px;
}

.productCard-info>div:nth-child(2) {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	margin-bottom: 5px;
	padding: 0 14px;
}

.productCard-info>div:nth-child(2) h3 {
	font-size: 14px;
	color: var(--color-gray-blue);
	font-weight: 400;
	text-transform: capitalize;
}

.productCard-info>div:nth-child(2) span {
	color: var(--color-gray-dark);
	font-weight: 500;
}

.productCard-info>div:nth-child(3) {
	padding: 0 14px;
	padding-top: 0;
	height: inherit;
}

.productCard-info>div:nth-child(3) span {
	font-size: 16px;
	color: var(--color-gray-dark)
}

.productCard-info>div:nth-child(3)>div:nth-child(1) span {
	color: var(--color-red);
	font-size: 11.5px !important;
	font-weight: 300;
	text-decoration: none;
}

.productCard-info>div:nth-child(3) div {
	margin-bottom: 10px;
}

.productCard-container>section {
	position: relative;
	z-index: 30;
	top: 94px;
	width: 98%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
}

.productCard-container section img {
	width: 27px;
	height: 27px;
	padding: 5px;
	border-radius: 5px;
	background: rgb(250, 250, 250, 0.75);
	cursor: pointer;
}

.productCard-noPrice {
	display: flex;
	justify-content: center;
}

.productCard-noPrice h4 {
	color: var(--color-gray-blue);
	font-size: 16px;
	font-weight: 400;
	padding: 30px;
	margin: 0;
}

.productCard-price-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.productCard-price-diference {
	display: flex;
	height: 20px;
	align-items: center;
	gap: 5px;
	justify-content: flex-end;
	margin: 0 !important;
}

.productCard-price-diference img {
	width: 20px;
}

.productCard-price-diference span {
	font-size: 12px !important;
	font-weight: 400;
}

.productCard-same-product>span {
	padding: 6px;
	border: 1px solid var(--color-gray-ligth);
	border-radius: 7px;
	font-size: 12px !important;
}