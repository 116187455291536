.cuentas-contenedor hr {
	height: 2px !important;
	border: 1px solid var(--color-gray-dark);
	opacity: 0.75;
}

.cuentas-contenedor h2 {
	font-weight: 400 !important;
}

.cuentas-contenedor {
	margin: 60px auto 30px auto;
	padding: 33px 20px 0 20px;
	width: 360px;
}

.modales-titulo_modal {
	font-size: 16px;
	font-weight: 500;
	color: var(--color-gray-dark);
}

.modales-texto_modal {
	font-size: 14px;
	font-weight: 300;
	color: var(--color-gray-dark);
	line-height: 1.64;
	margin: 0px;
}

.modales-contenedor_texto-div {
	padding: 15px;
}

.modales-boton {
	width: 50%;
	height: 36px;
	border: none;
	font-size: 1rem;
	font-weight: 500;
}

.modales-boton_confirmar {
	color: white;
	background-color: var(--color-gray-blue);
	border-radius: 0px 0px 0px 10px;
	font-size: 0.88rem;
}

.modales-boton_cancelar {
	background-color: white;
	color: var(--color-gray-blue);
	border-radius: 0px 0px 10px 0px;
	font-size: 0.88rem;
}

.modales-contenedor_formulario {
	padding: 10px;
}

.modales-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modales-modal_editar-boton {
	width: 100%;
	height: 49px;
	margin: 5px 0px;
	border-radius: 7px;
	font-size: 0.88rem;
}

.modales-modal_editar-btn_editar {
	color: white;
	background-color: var(--color-gray-blue);
	border: none;
	margin-bottom: 0px;
	font-size: 14px;
}

.modal-modal_confirmacion_modificacion-mensaje {
	font-size: 1rem;
	font-weight: 300;
	color: black;
	text-align: center;
}

.modal-modificacion-cuenta-proveedor-icono {
	width: 64.7px;
	height: 64.7px;
}

.selectCategory-container {
	box-shadow: -2px 4px 13px 0 rgb(0 0 0 / 25%);
	border-radius: 6px;
	width: 300px !important;
	position: absolute;
	z-index: 1000;
	background-color: #fff;
	overflow: hidden;
}

.selectCategory-container-row {
	padding: 15px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.selectCategory-dropdown-category {
	flex-direction: column;
}

.selectCategory-dropdown-subcategory {
	padding: 15px 20px !important;
	font-size: 0.75rem;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
	text-transform: capitalize;
	cursor: pointer;
}


.selectCategory-container h6 {
	font-size: 0.75rem;
	color: var(--color-gray-blue);
	margin: 0;
}

.selectCategory-container h3 {
	font-size: 12px !important;
	font-weight: 500 !important;
	color: var(--color-gray-blue);
	margin: 0;
	text-transform: capitalize;
}

.dropdowbCategoryRowOpen>h6 {
	color: white;
}

@media (min-width: 744px) {
	.cuentas-contenedor {
		margin-top: 40px;
		padding: 50px 50px;
		width: 744px;
	}

	.selectCategory-container {
		width: 355px;
	}

	.selectCategory-container h6,
	.dropdowbCategoryRowOpen>h6,
	.selectCategory-container h3,
	.selectCategory-dropdown-subcategory {
		font-size: 0.75rem;
	}
}

@media (min-width: 1298px) {
	.cuentas-contenedor {
		margin-top: 90px;
		width: 1298px;
		margin-bottom: 30px;
	}

	.selectCategory-container h6,
	.dropdowbCategoryRowOpen>h6,
	.selectCategory-container h3,
	.selectCategory-dropdown-subcategory {
		font-size: 0.6rem;
	}
}

@media (min-width: 1920px) {
	.modal-modificacion-cuenta-proveedor-icono {
		width: 80px;
		height: 80px;
	}
}