.quotationSearcher-container>div {
    width: 320px;
}

.quotationSearcher-container section {
    display: flex;
    gap: 2px;
    border-bottom: 2px solid var(--color-gray-blue);
    margin: 15px 0;
    overflow: overlay;
}

.quotationSearcher-container section div {
    color: var(--color-gray-blue);
    background-color: white;
    border-radius: 10px 10px 0 0;
    border: 2px solid var(--color-gray-blue);
    border-bottom: none;
    padding: 5px 20px 0 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    min-width: max-content;
}

.quotationSearcher-container section div:hover {
    background-color: var(--color-gray-blue-ligther);
}

.quotationSearcher-container section div[disabled] {
    color: white;
    background-color: var(--color-gray-blue);
    cursor: initial;
}